import React, { useState } from 'react';

export const AuthContext = React.createContext({});
export const AuthConsumer = AuthContext.Consumer;

function AuthProvider(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [email, setEmail] = useState('');
    const [isStaff, setIsStaff] = useState(false);
    const [firstName, setFirstName] = useState('Anonymous');

    const setAuth = (email, staff, firstName) => {
        setIsLoggedIn(true);
        setEmail(email);
        setIsStaff(staff);
        setFirstName(firstName);
    };

    const logout = () => {
        setIsLoggedIn(false);
        setEmail('');
        setIsStaff(false);
        setFirstName('Anonymous');
    }

    return (
        <AuthContext.Provider value={{
            state: {
                isLoggedIn: isLoggedIn,
                email: email,
                isStaff: isStaff,
                firstName: firstName
            },
            setAuth: setAuth,
            logout: logout
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;