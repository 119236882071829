import React, { useState, useContext, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from 'components/context/AuthProvider';
import { ErrorContext } from 'components/context/ErrorProvider';
import NotifyError from 'components/notification/error';
import jwt_decode from 'jwt-decode';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '../css/login.css';


const TOKEN_AUTH = gql`
mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

function Login(props) {
    const auth = useContext(AuthContext);
    const err = useContext(ErrorContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [tokenAuth, { error, data }] = useMutation(TOKEN_AUTH);

    const localAuthenticate = token => {
        localStorage.setItem('token', token);
        const { email, staff, first_name } = jwt_decode(token);
        auth.setAuth(email, staff, first_name);
    }

    useEffect(() => {
        if (data && data.tokenAuth) {
            localAuthenticate(data.tokenAuth.token);
            setRedirect(true);
        }
        else if (error) {
            err.addError("Please enter valid credentials.");
        }
    }, [data, error]);

    useEffect(() => {
        return () => {
            err.removeAll();
        };
    }, []);

    return (
        <div className="login" style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            background: 'linear-gradient(to right, #2a0845, #6441A5)'
        }}>
            {(redirect || auth.state.isLoggedIn) && <Redirect to={{ pathname: '/browse' }} />}
            <NotifyError />
            <div className="login-container"
                style={{
                    position: 'relative',
                    zIndex: '1',
                    marginTop: '-100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <div className="header-logo" style={{ paddingBottom: '20px' }}>
                    <Link to="/" >
                        <img src="assets/img/logo.png" alt="Nozu Logo" />
                    </Link>
                </div>
                <div className="login-box" style={{
                    width: '480px',
                    padding: '60px 40px',
                    backgroundColor: '#36393f',
                    boxShadow: '0 2px 10px 0 rgba(0,0,0,.2)',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div className="login-back" style={{
                        alignSelf: 'flex-start'
                    }}>
                        <Link to="/"><button className="btn-clear"><ArrowBackIcon /></button></Link>
                    </div>
                    <h2 className="nonbold">Welcome back!</h2>
                    <p className="text">Please login to your account.</p>
                    <div className="login-form-container" style={{
                        width: '100%'
                    }}>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                tokenAuth({ variables: { email: email, password: password } });
                            }}
                            style={{
                                marginTop: '30px',
                                width: '100%'
                            }}>
                            <input
                                className="input-primary input-icon-login"
                                type="email"
                                placeholder="E-Mail"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                                style={{
                                    width: '100%'
                                }}
                            />
                            <input
                                className="input-primary input-icon-password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                required
                                style={{
                                    width: '100%'
                                }}
                            />
                            <button className="btn-primary" type="submit"
                                style={{
                                    marginTop: '12px',
                                    fontSize: '16px',
                                    width: '100%'
                                }}>Login</button>
                            <p
                                style={{ marginTop: '12px' }} className="subtext">No account? <a className="link" href="#register">Create one</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Logout(props) {
    const auth = useContext(AuthContext);

    if (auth.state.isLoggedIn) {
        localStorage.removeItem('token');
        auth.logout();
    }

    return <Redirect to={{ pathname: '/login' }} />;
}

export default Login;