import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';

function Register(props) {
    //const [email, setEmail] = useState('');

    const createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: '16px',
                    color: 'green',
                    '::placeholder': {
                        color: 'blue',
                    },
                },
                invalid: {
                    color: 'red',
                },
            }
        }
    }

    const submitCreateToken = () => {
        props.stripe.createToken({ name: "Name" }).then((res) => {
            console.log(res.token.id);
        });
    }

    return (
        <div className="checkout">
            <p>Would you like to complete the purchase?</p>
            <div className="pay-container" style={{
                width: '400px'
            }}>
                <div className="card-container" style={{
                    border: '1px #000 solid'
                }}>
                    <CardElement
                        //onChange={}
                        {...createOptions()}
                    />
                </div>
                <button onClick={submitCreateToken}>Start Trial</button>
            </div>
        </div>
    );

    // return (
    //     <div className="register">
    //         <form>
    //             <input
    //                 className="input-primary input-icon-login"
    //                 type="email"
    //                 placeholder="E-Mail"
    //                 value={email}
    //                 onChange={e => setEmail(e.target.value)}
    //                 required
    //             />
    //             <button className="btn-primary" type="submit">Continue</button>
    //         </form>
    //     </div>
    // );
}

export default injectStripe(Register);