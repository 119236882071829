import { useEffect } from 'react';
import { useContext } from 'react'
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from 'components/context/AuthProvider';

const VERIFY_TOKEN = gql`
mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

function usePersistentAuth() {
    const auth = useContext(AuthContext);
    const [verifyToken] = useMutation(VERIFY_TOKEN);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token === null)
            token = "123";

        verifyToken({
            variables: { token: token }
        }).then((res) => {
            if (res.data && res.data.verifyToken) {
                const { email, staff, first_name } = res.data.verifyToken.payload;
                auth.setAuth(email, staff, first_name);
            }
            // error means not logged in
        }).catch((error) => {
            // set to not logged in
            auth.logout();
        });
    }, [auth, verifyToken]);

    // null for no JSX
    //return null;
}

export default usePersistentAuth;