import React, { Component } from 'react'

class Error404 extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div>
                <h1>404 Not Found</h1>
            </div>
        )
    }
}

export default Error404
