import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ShowcaseCover4 from './ShowcaseCover4';
import ShowcaseEpisode from './ShowcaseEpisode';
import ShowcaseEpisodeCurrent from './ShowcaseEpisodeCurrent';
import EventListener from 'react-event-listener';
import '../../css/showcase.css';


class Showcase4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            baseCoverWidth: 388,
            swipePath: 100,
            coverPerPage: 5,
            iteration: 0,
            transition: false,
            blockSwipe: false
        }

        this.showcase = React.createRef();

        this.showcaseSwipe = this.showcaseSwipe.bind(this);
        this.resizeShowcase = this.resizeShowcase.bind(this);
        this.chunk = this.chunk.bind(this);
    }

    componentDidMount() {
        this.resizeShowcase();
    }

    showcaseSwipe(direction) {
        if (!this.state.blockSwipe) {
            let iter = this.state.iteration
            if (direction === -1) {
                if (iter >= (Math.ceil(this.props.data.length / this.state.coverPerPage) - 1)) {
                    iter = 0;
                }
                else {
                    iter = iter + 1;
                }
            }
            else if (direction === 1) {
                if (iter <= 1) {
                    iter = 0;
                }
                else {
                    iter = iter - 1;
                }

            }

            let swipePath = this.state.swipePath * iter;
            if (iter >= (Math.ceil(this.props.data.length / this.state.coverPerPage) - 1)) {
                let lastSwipePath = this.state.swipePath - ((this.state.swipePath / this.state.coverPerPage) * (this.props.data.length % this.state.coverPerPage));
                if (lastSwipePath === 100)
                    lastSwipePath = 0;

                swipePath = swipePath - lastSwipePath;
            }

            this.setState({ transition: true, blockSwipe: true }, () => {
                this.showcase.current.style.transform = 'translate3d(' + (swipePath * -1) + '%, 0, 0)';
            });

            setTimeout(() => {
                this.setState({
                    transition: false,
                    blockSwipe: false,
                    iteration: iter
                });
            }, 600);
        }
    }

    resizeShowcase() {
        if (Math.ceil(document.body.clientWidth / this.state.baseCoverWidth) !== this.state.coverPerPage) {
            this.setState({ coverPerPage: Math.ceil(document.body.clientWidth / this.state.baseCoverWidth) });
        }
    }

    chunk(arr, chunkSize = 1, cache = []) {
        const tmp = [...arr];
        if (chunkSize <= 0)
            return cache;

        while (tmp.length) {
            cache.push(tmp.splice(0, chunkSize))
        }

        const lastChunkSize = (cache[cache.length - 1].length);
        if (lastChunkSize < chunkSize) {
            const diff = chunkSize - lastChunkSize;
            for (let i = diff; i > 0; i--) {
                cache[cache.length - 1].push(cache[0][i]);
            }
        }
        
        return cache;
    }

    render() {
        const coverGroup = this.chunk(this.props.data, this.state.coverPerPage);

        return (
            <div className="showcase" style={{
                paddingBottom: '60px'
            }}>
                <EventListener
                    target="window"
                    onResize={this.resizeShowcase}
                />
                <div className="section-showcase-width" style={this.props.headWidth}>
                    <div className="showcase-head" style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '25px'
                    }}>
                        <h2 className="title">{this.props.title}</h2>
                        <div className="showcase-head-right">
                            <button className="btn-showcase" onClick={() => this.showcaseSwipe(1)}>
                                <span className="next-left fa-stack">
                                    <i className="far fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-chevron-left fa-stack-1x"></i>
                                </span>
                            </button>
                            <button className="btn-showcase" onClick={() => this.showcaseSwipe(-1)}>
                                <span className="next-right fa-stack">
                                    <i className="far fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-chevron-right fa-stack-1x"></i>
                                </span>
                            </button>
                            {/* <button className="btn-secondary">See All</button> */}
                        </div>
                    </div>
                </div>
                <div className="showcase-container" style={{
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    height: '256px'
                }}>
                    <div className="showcase-inner" ref={this.showcase} style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        transition: 'transform .5s ease-out'
                    }}>
                        {coverGroup.map((group, i) => (
                            <div className="cover-group" key={i} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '100%'
                            }}>
                                {group.map((cover, i) => {
                                    if(this.props.cover) {
                                        return <ShowcaseCover4 key={cover.id} index={i} data={cover} imagePerPage={this.state.coverPerPage} />
                                    }
                                    else {
                                        if(this.props.currentId !== cover.id) {
                                            return <ShowcaseEpisode key={cover.id} index={i} data={cover} imagePerPage={this.state.coverPerPage} />
                                        }
                                        else {
                                            return <ShowcaseEpisodeCurrent key={cover.id} index={i} data={cover} imagePerPage={this.state.coverPerPage} />
                                        }
                                    }
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

Showcase4.defaultProps = {
    currentId: 0
};

Showcase4.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    cover: PropTypes.bool.isRequired,
    currentId: PropTypes.number.isRequired,
    headWidth: PropTypes.object.isRequired
};

export default Showcase4
