import React, { Component } from 'react'
import VideoPlayer from './VideoPlayer/VideoPlayer';

class Watch extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    return (
      <div>
        <VideoPlayer
          videoSize={{
            width: '100vw',
            height: '100vh'
          }}
          // videoManifest={'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd'}
          // videoManifest={'https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd'}
          // videoManifest={'https://video.nozu.io/newres04/master.mpd'}
          // videoManifest={'https://nozu-test.ams3.digitaloceanspaces.com/live/2c02f3deff7dd702a1974f153f8b2347/dash/master.mpd'}
          // videoManifest={'https://stream.mux.com/kIGLGzr2yJoxBmkNsppaOBhgEEAGx8qa.m3u8'} // elfenlied01
          animeName={'Elfen Lied'}
          episodes={[{
            id: 1,
            name: 'Begegnung',
            season: 1,
            number: 1,
            progress: 100,
            image: '/assets/img/episode/episode10.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 2,
            name: 'Die Vernichtung',
            season: 1,
            number: 2,
            progress: 100,
            image: '/assets/img/episode/episode120.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 3,
            name: 'Im Innersten',
            season: 1,
            number: 3,
            progress: 100,
            image: '/assets/img/episode/episode240.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 4,
            name: 'Empfang',
            season: 1,
            number: 4,
            progress: 100,
            image: '/assets/img/episode/episode360.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 5,
            name: 'Herzenswärme',
            season: 1,
            number: 5,
            progress: 100,
            image: '/assets/img/episode/episode600.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 6,
            name: 'Zufällige Begegnung',
            season: 1,
            number: 6,
            progress: 76,
            image: '/assets/img/episode/episode900.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          },
          {
            id: 799,
            name: 'Aufeinandertreffen',
            season: 1,
            number: 7,
            progress: 0,
            image: '/assets/img/episode/episode1200.png',
            dashManifest: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/dash/master.mpd',
            spriteFile: 'https://nozu-test.ams3.digitaloceanspaces.com/live/elfenlied01/sprite.jpg',
            subtitleFile: 'https://nozu-test.ams3.digitaloceanspaces.com/stream/sub_en.ass'
          }]} />
      </div>
    )
  }
}

export default Watch
