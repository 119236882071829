import React, { Component } from 'react'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { AuthConsumer } from 'components/context/AuthProvider';
import Showcase4 from './Showcase/Showcase4';
import Wall from './Wall/Wall';
import EventListener from 'react-event-listener';
import Foot from './Foot';
import '../css/landing.css';
import '../css/browse.css';

const ALL_ANIMES = gql`
{
  allCategories {
    name
    anime {
      id
      name
      description
      liked
      coverUrl
      backgroundUrl
    }
  }
}
`;

function Browse(props) {
  const { loading, error, data } = useQuery(ALL_ANIMES);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return <BrowsePage data={data} />;
}

class BrowsePage extends Component {
  constructor(props) {
    super(props);

    this.searchbar = React.createRef();

    this.btnMenu = this.btnMenu.bind(this);
    this.btnSearch = this.btnSearch.bind(this);
    this.btnUserSettings = this.btnUserSettings.bind(this);
    this.scrollHead = this.scrollHead.bind(this);

    this.state = {
      navToggle: false,
      searchToggle: false,
      userSettingsToggle: false,
      headBackground: false,
      spotlight: this.props.data.allCategories[0].anime[4],
      wallCover: [
        this.props.data.allCategories[1].anime[0],
        this.props.data.allCategories[0].anime[0],
        this.props.data.allCategories[1].anime[3]
      ],
      // cover: this.props.data.allAnimes
      showcase: this.props.data.allCategories
    }
  }

  btnMenu() {
    this.setState({ navToggle: !this.state.navToggle });
  }

  btnSearch() {
    this.setState({ searchToggle: !this.state.searchToggle }, () => {
      if (this.state.searchToggle) {
        this.searchbar.current.focus();
      }
    });
  }

  btnUserSettings() {
    this.setState({ userSettingsToggle: !this.state.userSettingsToggle });
  }

  scrollHead() {
    if (window.pageYOffset > 100) {
      if (!this.state.headBackground) {
        this.setState({ headBackground: true });
      }
    }
    else {
      if (this.state.headBackground) {
        this.setState({ headBackground: false });
      }
    }
  }

  render() {
    const page_width = { maxWidth: '100%', paddingLeft: '60px', paddingRight: '60px' };

    return (
      <div>
        <EventListener
          target="window"
          onScroll={this.scrollHead}
        />
        <div className="section-head" style={{
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('` + this.state.spotlight.backgroundUrl + `')`,
          backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          height: '1080px',
        }}>
          <div className="section-head-overlay"></div>
          <div className="section-head-width" style={{ ...page_width, position: 'relative', zIndex: '3', height: '1080px' }}>
            <header className="browse-header">
              <div className={"header-scroll-background " + (this.state.headBackground ? 'show-head-background' : '')} style={{
                position: 'fixed',
                zIndex: '1',
                left: '0',
                right: '0',
                width: '100%',
                height: '87px',
                backgroundColor: 'var(--bg-color-light)'
              }}></div>
              <div className="header-container" style={{
                position: 'fixed',
                left: '0',
                right: '0',
                zIndex: '1',
                padding: '20px 60px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start'
              }}>
                <div className="header-main" style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '50px'
                }}>
                  <div className={"header-logo " + (this.state.searchToggle ? 'hide-mobile-medium' : '')}><a href="/"><img src="assets/img/logo.png" alt="Nozu Logo" /></a></div>
                  <div className="form-group" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '20px',
                  }}>
                    <input ref={this.searchbar} className={"form-search " + (this.state.searchToggle ? 'search-active' : '')} type="text" name="query" autoComplete="off" placeholder="Type to search..." />
                    <button className="btn-search" onClick={this.btnSearch}><i className={"nav-font fas " + (this.state.searchToggle ? 'fa-times' : 'fa-search')}></i></button>
                  </div>
                </div>
                <button className="btn-menu hide-all" onClick={this.btnMenu}><i className="fas fa-bars"></i></button>
                <div className={"header-nav " + (this.state.navToggle ? 'show-flex' : 'hide-mobile')} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}>
                  <div className={"header-nav-left " + (this.state.searchToggle ? 'hide-desktop' : '')} style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <ul className="nav-genre">
                      <li><a href="#genre">My List</a></li>
                      <li><a href="#genre">Action</a></li>
                      <li><a href="#genre">Comedy</a></li>
                      <li><a href="#genre">Fantasy</a></li>
                      <li><a href="#genre">Psychological</a></li>
                      <li><a href="#genre">Romance</a></li>
                      <li><a href="#genre">Splatter</a></li>
                    </ul>
                  </div>
                  <div className="header-nav-placeholder"></div>
                  <div className="header-nav-right" style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}>
                    <div className="header-user">
                      <button className="btn-user" onClick={this.btnUserSettings} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textDecoration: 'none'
                      }}>
                        <AuthConsumer>
                          {value => (
                            <React.Fragment>
                              <div className="user-circle" style={{
                                backgroundColor: 'var(--main-color)',
                                borderRadius: '50%',
                                width: '50px',
                                height: '50px',
                                color: '#fff',
                                fontSize: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}>{value.state.firstName.substring(0, 1)}</div>
                              <span className="nav-font">{value.state.firstName}</span>
                            </React.Fragment>
                          )}
                        </AuthConsumer>
                        <i className="fas fa-caret-down nav-font"></i>
                      </button>
                    </div>
                  </div>
                  <div className={"user-settings " + (this.state.userSettingsToggle ? 'show-flex' : 'hide-all')} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    flexBasis: '100%',
                    width: '100%'
                  }}>
                    <ul style={{
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: 'var(--bg-color-semidark)',
                      width: '14rem',
                      marginTop: '15px',
                      padding: '0.875rem 0'
                    }}>
                      <li><a href="#usersettings"><span>Settings</span></a></li>
                      <li><a href="#usersettings"><span>Billing</span></a></li>
                      <li><Link to="/logout"><span>Logout</span></Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </header>
            <div className="spotlight-overlay"></div>
            <div className="spotlight-outer" style={{
              position: 'absolute',
              top: '0',
              left: '0',
              paddingLeft: '60px',
              height: '100%',
              display: 'flex',
              alignItems: 'center'
            }}>
              <div className="spotlight-container"
                style={{
                  width: '685px'
                }}>
                <div className="spotlight-content">
                  <h1 className="heading" style={{ marginBottom: '5px', fontSize: '65px' }}>{this.state.spotlight.name}</h1>
                  <div className="spotlight-rating" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <p className="like" style={{ marginRight: '15px' }}><span style={{ color: 'var(--main-color)' }}>86%</span> Like This</p>
                    <button className={"btn-like " + (this.state.spotlight.liked ? 'selected' : '')}>
                      <i className="far fa-thumbs-up"></i>
                    </button>
                    <button className={"btn-like " + (this.state.spotlight.liked === false ? 'selected' : '')}>
                      <i className="far fa-thumbs-down"></i>
                    </button>
                  </div>
                  <p className="text" style={{ fontSize: '26px' }}>{this.state.spotlight.description}</p>
                  <button style={{ marginTop: '40px', marginRight: '20px' }} className="btn-primary">Play <i style={{ marginLeft: '16px', fontSize: '16px' }} className="fas fa-play"></i></button>
                  <button className="btn-outline">My List <i style={{ marginLeft: '16px', fontSize: '16px' }} className="fas fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-showcase"
          style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-semidark)',
            paddingTop: '127px'
          }}>
          {/* <Showcase4 data={this.state.cover} cover={true} title={"Continue Watching"} headWidth={page_width} /> */}
          {/* <Showcase4 data={this.state.cover.slice().reverse()} cover={true} title={"Trending Now"} headWidth={page_width} /> */}
          {this.state.showcase.map((cat) => <Showcase4 data={cat.anime} cover={true} title={cat.name} headWidth={page_width} />)}
        </div>

        <div className="section-wall">
          <div className="wall-outer" style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-semidark)'
          }}>
            <Wall data={this.state.wallCover} title={"You know these animes?"} headWidth={page_width} />
          </div>
        </div>

        <div
          className="section-showcase"
          style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-semidark)',
            paddingTop: '127px'
          }}>
          <Showcase4 data={this.state.showcase[0].anime.slice().reverse()} cover={true} title={"Because you watched Sword Art Online"} headWidth={page_width} />
          <Showcase4 data={this.state.showcase[1].anime.slice().reverse()} cover={true} title={"Watch again"} headWidth={page_width} />
        </div>

        <Foot width={page_width} />
      </div >
    )
  }
}

export default Browse
