import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from 'components/context/AuthProvider';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route {...rest} render={props => (
      auth.state.isLoggedIn ? (
        <Component {...props} />
      ) : (
          <Redirect to={{
            pathname: '/login',
            // state: { from: props.location }
          }}
          />
        )
    )} />
  );
}

export default PrivateRoute;  