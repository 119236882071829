import React, { Component } from 'react'
import '../css/foot.css';


class Foot extends Component {
    render() {
        return (
            <footer className="footer"
                style={{
                    width: '100%',
                    backgroundColor: 'var(--bg-color-dark)',
                }}>
                <div className="footer-container" style={{
                    ...this.props.width,
                    paddingTop: '60px',
                    paddingBottom: '60px'
                }}>
                    <ul className="footer-content upper" style={{ display: 'flex', flexDirection: 'row' , marginBottom: '30px' }}>
                        <li><a href="#about">ABOUT</a></li>
                        <li><a href="/browse">BROWSE</a></li>
                        <li><a href="#help">HELP</a></li>
                        <li style={{ marginLeft: 'auto' }}><a href="/">&copy; 2020 Nozu</a></li>
                    </ul>
                    <ul className="footer-content lower" style={{ display: 'flex', flexDirection: 'row' }}>
                        <li><a href="#tos">Terms of Use</a></li>
                        <li><a href="#privacy">Privacy Policy</a></li>
                    </ul>
                </div>
            </footer>
        )
    }
}

export default Foot;
