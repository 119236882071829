import React, { Component } from 'react'
import axios from 'axios';
import gql from 'graphql-tag';
import { ApolloConsumer, Query } from 'react-apollo';

const SIGNED_UPLOAD_URL = gql`
query SignedUploadUrl($file: String) {
    signedUploadUrl(file: $file) {
      sourceId
      signedUrl
    }
  }  
`;

const START_TRANSCODING = gql`
query StartTranscoding($episodeId: Int) {
    startTranscoding(episodeId: $episodeId)
  }  
`;

const DashboardTranscoding = ({ episodeId }) => (
    <Query query={START_TRANSCODING} variables={{ episodeId }}>
        {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            return (
                <p>Video has started trancoding.</p>
            );
        }}
    </Query>
);


class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: {},
            ready: false,
            url: "",
            id: -1,

            uploaded: false
        };

        this.setFile = this.setFile.bind(this);
        this.upload = this.upload.bind(this);
        this.setUploadData = this.setUploadData.bind(this);
    }

    setFile(e) {
        this.setState({ file: e.target.files[0] });
    }

    setUploadData(upload) {
        this.setState({ url: upload.signedUrl, id: upload.sourceId, ready: true });
    }

    upload() {
        if (this.state.ready) {
            let options = {
                headers: { 'Content-Type': this.state.file.type },
                onUploadProgress: progressEvent => console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }

            axios.put(
                this.state.url,
                this.state.file,
                options
            ).then((res) => {
                console.log("finished");
                this.setState({ uploaded: true });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.setState({ file: {}, ready: false });
            });
        }
    }

    render() {
        return (
            <ApolloConsumer>
                {client => (
                    <React.Fragment>
                        {this.state.uploaded &&
                            <DashboardTranscoding episodeId={this.state.id} />
                        }
                        <form onSubmit={this.onFormSubmit}>
                            <input type="file" onChange={(e) => this.setFile(e)} />
                        </form>
                        <button onClick={async () => {
                            const { data } = await client.query({
                                query: SIGNED_UPLOAD_URL,
                                variables: { file: this.state.file.name }
                            });
                            this.setUploadData(data.signedUploadUrl);
                            this.upload();
                        }}>Upload</button>
                    </React.Fragment>
                )}
            </ApolloConsumer>
        )
    }
}

export default Dashboard