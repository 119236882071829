import React, { Component } from 'react'
import shaka from 'shaka-player'
import PropTypes from 'prop-types';
import VideoControls from './VideoControls';
import muxjs from "mux.js";


class VideoPlayer extends Component {
    constructor(props) {
        super(props);

        this.video = React.createRef();
        this.videoContainer = React.createRef();

        this.state = {
            videoManifest: '',
            subtitleFile: '',
            spriteFile: '',
            videoId: 0,
            animeInfo: {},
            playing: false,
            muted: false,
            fullscreen: false,
            videoDuration: 0,
            videoTimer: 0,
            imgPerSprite: 0,
            bufferEnd: 0
        }

        this.getCurrentEpisode = this.getCurrentEpisode.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
        this.pause = this.pause.bind(this);
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.updateDuration = this.updateDuration.bind(this);
        this.updateTimer = this.updateTimer.bind(this);
        this.seek = this.seek.bind(this);
        this.updateBuffer = this.updateBuffer.bind(this);

        this.streamSuspend = this.streamSuspend.bind(this);
        this.streamWaiting = this.streamWaiting.bind(this);
        this.streamEnded = this.streamEnded.bind(this);
    }

    getCurrentEpisode() {
        let currEpisode = {};

        for (let i = 0; i < this.props.episodes.length; i++) {
            if (this.props.episodes[i].progress < 100) {
                currEpisode = this.props.episodes[i];
                break;
            }
        }
        this.setState({
            videoId: currEpisode.id,
            // videoManifest: currEpisode.dashManifest,
            videoManifest: 'https://nozu-demo.fra1.cdn.digitaloceanspaces.com/assets/video/mob100.mp4',
            subtitleFile: currEpisode.subtitleFile,
            // spriteFile: currEpisode.spriteFile,
            spriteFile: 'https://nozu-demo.pages.dev/assets/img/sprites02.jpg',
            animeInfo: {
                id: currEpisode.id,
                animeName: this.props.animeName,
                episodeName: currEpisode.name,
                season: currEpisode.season,
                number: currEpisode.number,
                image: currEpisode.image
            }
        }, () => {
            this.loadPlayer();
        });
    }

    streamSuspend() {
        console.log("stream supspended");
    }

    streamWaiting() {
        console.log("stream waiting");
    }

    streamEnded() {
        console.log("stream ended");
    }

    componentDidMount() {
        this.getCurrentEpisode();
    }

    componentWillUnmount() {
        // this.subs.dispose();
        this.player.destroy();
    }

    loadPlayer() {
        //load mux.js before shaka player (needed to support ts files)
        window.muxjs = muxjs;

        shaka.polyfill.installAll();
        if (shaka.Player.isBrowserSupported()) {
            this.player = new shaka.Player(this.video.current);

            // for developing
            this.player.addEventListener('error', (event) => console.log(event.detail));

            // fetch(this.state.keyFile)
            //     .then(function (response) {
            //         return response.json();
            //     })
            //     .then(function (keys) {
            //         this.player.configure({
            //             drm: {
            //                 clearKeys: keys
            //             }
            //         });
            //     }.bind(this));

            //EZDRM configuration
            // this.player.configure({
            //     drm: {
            //       servers: {
            //         'com.widevine.alpha': 'https://widevine-dash.ezdrm.com/proxy?pX=0C5021',
            //         'com.microsoft.playready': 'https://playready.ezdrm.com/cency/preauth.aspx?pX=F7EF4E'
            //       }
            //     }
            //   });

            // this.player.configure({
            //     streaming: {
            //       bufferingGoal: 200
            //     }
            //   });

            // check if start value is in local storage
            let start = 0;
            if (localStorage.getItem(this.state.videoId) !== null) {
                start = localStorage.getItem(this.state.videoId);
            }

            this.player.load(this.state.videoManifest, start)
                // .then(() => this.loadSubtitles())
                .then(() => console.log("subtitle ocopus disabled"))
                .catch((event) => console.error("Player error: " + event.code));

            this.player.addEventListener('adaptation', () => this.showRes());

        }
        else {
            throw new Error("Browser not supported");
        }
    }

    loadSubtitles() {
        const options = {
            video: this.video.current,
            subUrl: this.state.subtitleFile,
            debug: false,
            workerUrl: '/js/subtitles-octopus-worker.js'
        };
        this.subs = new window.SubtitlesOctopus(options);
        // window.SubtitlesOctopus = undefined;
    }

    showRes() {
        console.log(this.player.getStats().height);
    }

    togglePlay() {
        this.setState(prevState => ({ playing: !prevState.playing }), () => {
            if (this.state.playing) {
                this.video.current.play();
            }
            else {
                this.video.current.pause();
            }
        });
    }

    pause() {
        if (this.state.playing) {
            this.togglePlay();
        }
    }

    toggleMute() {
        this.setState(prevState => ({ muted: !prevState.muted }), () => {
            this.video.current.muted = this.state.muted;
        });
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement }, () => {
            if (this.state.fullscreen) {
                if (this.videoContainer.current.requestFullscreen) {
                    this.videoContainer.current.requestFullscreen();
                }
                else if (this.videoContainer.current.mozRequestFullScreen) {
                    this.videoContainer.current.mozRequestFullScreen();
                }
                else if (this.videoContainer.current.webkitRequestFullscreen) {
                    this.videoContainer.current.webkitRequestFullscreen();
                }
            }
            else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
                else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                }
                else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        });
    }

    updateDuration() {
        this.setState({
            videoDuration: this.video.current.duration,
            imgPerSprite: Math.ceil(this.video.current.duration / this.getSpriteInterval(this.video.current.duration))
        });
    }

    getSpriteInterval(dur) {
        let interval = 0;
        if (dur <= 120) {
            interval = 2;
        }
        else if (dur <= 120) {
            interval = 2;
        }
        else if (dur <= 600) {
            interval = 5;
        }
        else if (dur <= 1800) {
            interval = 10;
        }
        else if (dur <= 3600) {
            interval = 20;
        }
        else {
            interval = 30;
        }

        return interval;
    }

    updateTimer() {
        this.setState({ videoTimer: this.video.current.currentTime }, () => {
            // localStorage.setItem(this.state.videoId, this.state.videoTimer);
        });
    }

    updateBuffer() {
        if (this.video.current.buffered.length > 0) {
            let buffer = this.video.current.buffered.end((this.video.current.buffered.length - 1));
            if (this.state.bufferEnd !== buffer) {
                this.setState({ bufferEnd: buffer }, () => {
                    // console.log(this.state.bufferEnd);
                });
            }
        }
    }

    seek(input_secs) {
        let input_secs_rounded = Math.round(input_secs * 10) / 10;

        this.setState({ videoTimer: input_secs_rounded });
        this.video.current.currentTime = input_secs_rounded;
    }

    render() {
        return (
            <div className="video-container" ref={this.videoContainer} style={{
                ...this.props.videoSize,
                position: 'relative',
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                overflow: 'hidden'
            }}>
                <video
                    ref={this.video}
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        backgroundColor: '#000',
                        outline: 'none'
                    }}
                    onCanPlay={this.updateDuration}
                    onTimeUpdate={this.updateTimer}
                    onProgress={this.updateBuffer}
                    onSuspend={this.streamSuspend}
                    onWaiting={this.streamWaiting}
                    onEnded={this.streamEnded}>
                </video>
                <VideoControls
                    togglePlayHandler={this.togglePlay}
                    playing={this.state.playing}
                    pauseHandler={this.pause}
                    toggleMuteHandler={this.toggleMute}
                    muted={this.state.muted}
                    toggleFullscreenHandler={this.toggleFullscreen}
                    fullscreen={this.state.fullscreen}
                    duration={this.state.videoDuration}
                    timer={this.state.videoTimer}
                    seekHandler={this.seek}
                    sprite={this.state.spriteFile}
                    imgPerSprite={this.state.imgPerSprite}
                    bufferEnd={this.state.bufferEnd}
                    episodes={this.props.episodes}
                    info={this.state.animeInfo} />
            </div>
        )
    }
}

VideoPlayer.propTypes = {
    videoSize: PropTypes.object.isRequired,
    animeName: PropTypes.string.isRequired,
    episodes: PropTypes.array.isRequired
};

export default VideoPlayer