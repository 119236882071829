import React, { useState } from 'react';

export const ErrorContext = React.createContext({});
export const ErrorConsumer = ErrorContext.Consumer;

function ErrorProvider(props) {
    const [errorArr, setErrorArr] = useState([]);

    const addError = (err) => {
        setErrorArr([...errorArr, { message: err }]);
    };

    const removeError = (index) => {
        let newArr = [...errorArr];
        newArr.splice(index, 1);
        setErrorArr(newArr);
    }

    const removeAll = () => {
        setErrorArr([]);
    }

    return (
        <ErrorContext.Provider value={{
            state: {
                error: errorArr
            },
            addError: addError,
            removeError: removeError,
            removeAll: removeAll
        }}>
            {props.children}
        </ErrorContext.Provider>
    );
}

export default ErrorProvider;