import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { AuthConsumer } from 'components/context/AuthProvider';

class ShowcaseCover4 extends Component {
    render() {
        let zoom = {};
        if (this.props.index % this.props.imagePerPage === 0) {
            zoom = { transformOrigin: 'left center' }
        }
        else if (this.props.index % this.props.imagePerPage === (this.props.imagePerPage - 1)) {
            zoom = { transformOrigin: 'right center' }
        }

        return (
            <AuthConsumer>
                {context => {
                    if (context.state.isLoggedIn) {
                        return (
                            <div className="cover-container" style={{
                                padding: '0 2px',
                                position: 'relative',
                                ...zoom
                            }}>
                                <Link to={"/video/" + this.props.data.id}>
                                    <img src={this.props.data.coverUrl} alt="" style={{ maxWidth: '100%', width: '380px', height: 'auto' }} />
                                    <div className="cover-overlay"></div>
                                </Link>
                                <div className="cover-info" style={{
                                    position: 'absolute',
                                    left: '25px',
                                    right: '25px',
                                    bottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end'
                                }}>
                                    <div className="cover-info-left">
                                        <h2 className="semititle">{this.props.data.name}</h2>
                                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext"><span style={{ color: 'var(--main-color)' }}>86%</span> Like This</p>
                                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext">Subbed &bull; Dubbed</p>
                                    </div>
                                    <div className="cover-info-right" style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        <button className={"btn-like-outline " + (this.props.data.liked ? 'selected' : '')}>
                                            <i className="far fa-thumbs-up"></i>
                                        </button>
                                        <button className={"btn-like-outline " + (this.props.data.liked === false ? 'selected' : '')} style={{ marginLeft: '5px' }}>
                                            <i className="far fa-thumbs-down"></i>
                                        </button>
                                        <button className="btn-add-outline" style={{ marginLeft: '5px' }}>
                                            <i className="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="cover-progress" style={{
                                    width: '100%',
                                    height: '5px',
                                    backgroundColor: '#fff',
                                    marginTop: '-5px',
                                    position: 'relative',
                                    zIndex: '1'
                                }}>
                                    <div className="cover-progess-inner" style={{
                                        width: '40%',
                                        height: '100%',
                                        backgroundColor: 'var(--main-color)'
                                    }}></div>
                                </div>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div className="cover-container" style={{
                                padding: '0 2px',
                                position: 'relative',
                                ...zoom
                            }}>
                                <div className="cover-inner">
                                    <img src={this.props.data.coverUrl} alt="" style={{ maxWidth: '100%', width: '380px', height: 'auto' }} />
                                    <div className="cover-overlay"></div>
                                </div>
                                <div className="cover-info" style={{
                                    position: 'absolute',
                                    left: '25px',
                                    right: '25px',
                                    bottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end'
                                }}>
                                    <div className="cover-info-left">
                                        <h2 className="semititle">{this.props.data.name}</h2>
                                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext"><span style={{ color: 'var(--main-color)' }}>86%</span> Like This</p>
                                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext">Subbed &bull; Dubbed</p>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }}
            </AuthConsumer>
        )
    }
}

ShowcaseCover4.propTypes = {
    data: PropTypes.object.isRequired
};

export default ShowcaseCover4