import React, { Component } from 'react'
import PropTypes from 'prop-types';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


class ShowcaseEpisodeCurrent extends Component {
    render() {
        return (
            <div className="cover-container-no-zoom" style={{
                padding: '0 2px',
                position: 'relative',
                cursor: ''
            }}>
                <img className="cover-img-playing" src={this.props.data.image} alt="" style={{ maxWidth: '100%', width: '380px', height: 'auto' }} />
                <div className="cover-overlay-playing"></div>
                <div className="cover-icon-playing"><PlayArrowIcon /></div>
            </div>
        )
    }
}

ShowcaseEpisodeCurrent.propTypes = {
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    imagePerPage: PropTypes.number.isRequired
};

export default ShowcaseEpisodeCurrent;
