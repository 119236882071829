import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/utils/PrivateRoute';

import usePersistentAuth from 'components/utils/PersistentAuth';
import { AuthContext } from 'components/context/AuthProvider';

import Landing from './components/Landing';
import Browse from './components/Browse';
import Watch from './components/Watch';
import Login, { Logout } from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Error404 from './components/Error404';
import './global.css';

function App(props) {
  usePersistentAuth();
  const auth = useContext(AuthContext);
  const [loginLoaded, setLoginLoaded] = useState(false);

  useEffect(() => {
    if (auth.state.isLoggedIn != null)
      setLoginLoaded(true);
  }, [auth.state.isLoggedIn]);

  return (
    <div className="App">
      {loginLoaded &&
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <PrivateRoute exact path="/browse" component={Browse} />
            <PrivateRoute exact path="/video/:videoID([0-9a-zA-Z]+)" component={Watch} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route component={Error404} />
          </Switch>
        </Router>

      }
    </div>
  );
}

export default App;

