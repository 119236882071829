import React, { Component } from 'react'
import PropTypes from 'prop-types';


class ShowcaseEpisode extends Component {
    render() {
        let zoom = {};
        if (this.props.index % this.props.imagePerPage === 0) {
            zoom = { transformOrigin: 'left center' }
        }
        else if (this.props.index % this.props.imagePerPage === (this.props.imagePerPage - 1)) {
            zoom = { transformOrigin: 'right center' }
        }

        return (
            <div className="cover-container" style={{
                padding: '0 2px',
                position: 'relative',
                ...zoom
            }}>
                <a href="#watch">
                    <img src={this.props.data.image} alt="" style={{ maxWidth: '100%', width: '380px', height: 'auto' }} />
                    <div className="cover-overlay"></div>
                </a>
                <div className="cover-info" style={{
                    position: 'absolute',
                    left: '25px',
                    right: '25px',
                    bottom: '25px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end'
                }}>
                    <div className="cover-info-left">
                        <h2 className="semititle">{this.props.data.name}</h2>
                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext">Season {this.props.data.season}</p>
                        <p style={{ marginTop: '7px', opacity: '1' }} className="subtext">Episode {this.props.data.number}</p>
                    </div>
                    <div className="cover-info-right" style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                    </div>
                </div>
                <div className="cover-progress" style={{
                    width: this.props.data.progress + '%',
                    height: '5px',
                    backgroundColor: '#fff',
                    marginTop: '-5px',
                    position: 'relative',
                    zIndex: '1'
                }}>
                    <div className="cover-progess-inner" style={{
                        width: this.props.data.progess + '%',
                        height: '100%',
                        backgroundColor: 'var(--main-color)'
                    }}></div>
                </div>
            </div>
        )
    }
}

ShowcaseEpisode.propTypes = {
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    imagePerPage: PropTypes.number.isRequired
};

export default ShowcaseEpisode