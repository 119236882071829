import React, { Component } from 'react'
import Foot from './Foot';
import Showcase4 from './Showcase/Showcase4';
import '../css/landing.css';
import { Link } from 'react-router-dom';

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cover: [{
        id: 1,
        name: 'Blue Exorcist',
        coverUrl: 'assets/img/cover/blue.webp'
      },
      {
        id: 2,
        name: 'Assassination Classroom',
        coverUrl: 'assets/img/cover/classroom.webp'
      },
      {
        id: 3,
        name: 'One Punch Man',
        coverUrl: 'assets/img/cover/one.webp'
      },
      {
        id: 4,
        name: 'Sword Art Online',
        coverUrl: 'assets/img/cover/sao.webp'
      },
      {
        id: 5,
        name: 'Attack on Titan',
        coverUrl: 'assets/img/cover/aot.webp'
      },
      {
        id: 6,
        name: 'Blue Exorcist',
        coverUrl: 'assets/img/cover/blue.webp'
      },
      {
        id: 7,
        name: 'Assassination Classroom',
        coverUrl: 'assets/img/cover/classroom.webp'
      },
      {
        id: 8,
        name: 'One Punch Man',
        coverUrl: 'assets/img/cover/one.webp'
      },
      {
        id: 9,
        name: 'Sword Art Online',
        coverUrl: 'assets/img/cover/sao.webp'
      },
      {
        id: 10,
        name: 'Attack on Titan',
        coverUrl: 'assets/img/cover/aot.webp'
      },
      {
        id: 11,
        name: 'One Punch Man',
        coverUrl: 'assets/img/cover/one.webp'
      },
      {
        id: 12,
        name: 'Assassination Classroom',
        coverUrl: 'assets/img/cover/classroom.webp'
      }]
    }
  }

  render() {
    const page_width = { maxWidth: '1200px', margin: '0 auto' };
    const plus_icon = { color: 'var(--main-color)', display: 'table-cell', paddingRight: '13px' };

    return (
      <div>
        <div className="section-head" style={{
          width: '100%',
          // backgroundColor: 'var(--bg-color-light)'
        }}>
          <video src="assets/video/bg.mp4" autoPlay loop muted style={{
            width: '100%',
            height: '100vh',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
            objectPosition: 'right',
            objectFit: 'cover'
          }}></video>
          <div className="section-head-width" style={{ ...page_width, height: '100vh' }}>
            <header className="landing-header" style={{ paddingTop: '30px' }}>
              <div className="header-container-no-responsive" style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div className="header-logo"><img src="assets/img/logo.png" alt="Nozu Logo" /></div>
                <div className="header-sign-in"><Link to="/login"><button className="btn-primary">Sign In</button></Link></div>
              </div>
            </header >
            <div className="slider-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                textAlign: 'center'
              }}>
              <div className="slider-content" style={{ marginTop: '230px' }}>
                <h1 className="heading" style={{ marginBottom: '5px' }}>Welcome to Nozu</h1>
                <p className="text">The whole anime world in in one place.<br />Watch fresh Simulcasts or enjoy some awesome classics.<br />Start your free trial today.</p>
                <Link to="/login"><button style={{ marginTop: '40px' }} className="btn-primary">Watch free for 14 Days</button></Link>
              </div>
              <div className="slider-img" style={{ display: 'flex', justifyContent: 'flex-end', overflow: 'hidden' }}>
                {/* <img src="assets/img/slide2.png" alt="" /> */}
                <div className="slider-img-name" style={{ paddingTop: '20px' }}>
                  {/* <p className="subtext">Now Streaming</p>
                  <h2 className="subtitle">Psycho-Pass</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-showcase"
          style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-semidark)',
            paddingTop: '60px',
            paddingBottom: '80px'
          }}>
          <Showcase4 data={this.state.cover} cover={true} title={"New Shows"} headWidth={page_width} />
        </div>

        <div className="section-devices"
          style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-dark)'
          }}>
          <div className="section-head-width" style={page_width}>
            <div className="devices-container"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '30px',
              }}>
              <div className="devices-content" >
                <h1 className="heading" style={{ marginBottom: '5px' }}>Watch Anywhere</h1>
                <p className="text">Watch the best Animes from the comfort of your<br />living room or take it on the go with our handy<br />mobile apps.</p>
                <div class="btn-group" style={{ marginTop: '40px' }}>
                  <button className="btn-primary" style={{ marginRight: '10px', cursor: 'default' }}><i className="fas fa-laptop"></i> Web</button>
                  <a href="#android" className="btn-primary" style={{ marginRight: '10px' }}><i className="fab fa-android"></i> Android</a>
                  <a href="#ios" className="btn-primary" style={{ marginRight: '10px' }}><i className="fab fa-apple"></i> iOS</a>
                </div>
              </div>
              <img src={'assets/img/devices.png'} alt="" />
            </div>
          </div>
        </div>

        <div className="section-faq"
          style={{
            width: '100%',
            backgroundColor: 'var(--bg-color-semidark)',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <div className="faq-container" style={{
            width: '830px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '50px',
            paddingBottom: '100px'

          }}>
            <h2 className="title">Frequently Asked Questions</h2>
            <div className="collapse-container">
              <ul className="collapse">
                <li>
                  <div><i style={plus_icon} className="fas fa-plus"></i><h2 style={{ display: 'table-cell' }} className="semititle">Maecenas at suscipit nisi?</h2></div>
                  <div><p style={{ paddingLeft: '30px' }} className="text answer">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam</p></div>
                </li>
                <li>
                  <div><i style={plus_icon} className="fas fa-plus"></i><h2 style={{ display: 'table-cell' }} className="semititle">Integer venenatis tellus at massa?</h2></div>
                  <div><p style={{ paddingLeft: '30px' }} className="text answer">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam</p></div>
                </li>
                <li>
                  <div><i style={plus_icon} className="fas fa-plus"></i><h2 style={{ display: 'table-cell' }} className="semititle">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum?</h2></div>
                  <div><p style={{ paddingLeft: '30px' }} className="text answer">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p></div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="section-notify"
          style={{
            width: '100%',
            backgroundColor: 'var(--main-color)'
          }}>
          <div className="section-head-width" style={page_width}>
            <div className="notify-container"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '30px',
              }}>
              <div className="notify-content" >
                <h1 className="heading" style={{ marginBottom: '5px' }}>Sounds good?</h1>
                <p className="text">Don't wait any longer.<br />The anime world is waiting for you!</p>
                <Link to="/login"><button className="btn-secondary" style={{ marginTop: '40px' }}>Get it now!</button></Link>
              </div>
              <img src={'assets/img/notify.png'} alt="" />
            </div>
          </div>
        </div>

        <Foot width={page_width} />

      </div>
    )
  }
}

export default Landing
