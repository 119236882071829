import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// instead of apollo-boost
// import ApolloClient from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { ApolloProvider } from '@apollo/react-hooks';
import AuthProvider from 'components/context/AuthProvider';
import ErrorProvider from 'components/context/ErrorProvider';
import { StripeProvider, Elements } from 'react-stripe-elements';

const httpLink = createHttpLink({
    uri: 'https://backend.nozu.io:8443/graphql'
});

const authLink = setContext((_, { headers }) => {
    let token = localStorage.getItem('token');
    if (token === null) {
        token = "aaaaaaa.bbbbbbbb.ccccccc";
    }
    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthProvider>
            <ErrorProvider>
                <StripeProvider apiKey="pk_test_AX4sVTMKEeVrAvjFnoyKtm8I00kpWfX87n">
                    <Elements>
                        <App />
                    </Elements>
                </StripeProvider>
            </ErrorProvider>
        </AuthProvider>
    </ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
