import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ColorExtractor } from 'react-color-extractor'
import '../../css/wall.css';


class Wall extends Component {
    constructor(props) {
        super(props);

        this.setColor = this.setColor.bind(this);
        this.state = {
            cover: this.props.data
        }
    }

    setColor(id, color) {
        let arr = [];
        for (let i = 0; i < this.state.cover.length; i++) {
            arr = this.state.cover;
            if (arr[i].id === id) {
                arr[i] = { ...arr[i], color: color[0] }
            }
        }
        this.setState({ cover: arr });
    }

    render() {
        return (
            <div className="section-wall-width" style={this.props.headWidth}>
                <div className="wall-head" style={{
                    marginBottom: '25px'
                }}>
                    <h2 className="title">{this.props.title}</h2>
                </div>
                <div className="wall-container" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}>
                    {this.props.data.map(cover => (
                        <div className="wall-cover-container" key={cover.id} style={{
                            position: 'relative',
                            width: '100%',
                            marginRight: '4px',
                        }}>
                            <figure style={{
                                width: '100%',
                                overflow: 'hidden',
                                margin: '0',
                                paddingTop: '56.25%',
                                position: 'relative'
                            }}>
                                <ColorExtractor rgb getColors={color => this.setColor(cover.id, color)}>
                                    <img className="wall-cover" src={cover.backgroundUrl} alt="" style={{
                                        width: '100%',
                                        height: 'auto',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0
                                    }} />
                                </ColorExtractor>
                            </figure>
                            <div className="wall-overlay" style={{ '--large-cover-color': cover.color }}></div>
                            <div className="wall-cover-info" style={{
                                position: 'absolute',
                                bottom: '23px',
                                left: '20px',
                                right: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end'
                            }}>
                                <div className="wall-cover-info-left">
                                    <h2 className="semititle">{cover.name}</h2>
                                    <p style={{ marginTop: '7px' }} className="like"><span style={{ color: 'var(--main-color)' }}>86%</span> Like This</p>
                                    <p style={{ marginTop: '7px', opacity: '1' }} className="text">Subbed &bull; Dubbed</p>
                                    <button style={{ marginTop: '20px' }} className="btn-outline">Play <i style={{ marginLeft: '8px', fontSize: '14px' }} className="fas fa-play"></i></button>
                                </div>
                                <div className="wall-cover-info-right">
                                    <button className={"btn-like-outline " + (cover.liked ? 'selected' : '')}>
                                        <i className="far fa-thumbs-up"></i>
                                    </button>
                                    <button className={"btn-like-outline " + (cover.liked === false ? 'selected' : '')} style={{ marginLeft: '5px' }}>
                                        <i className="far fa-thumbs-down"></i>
                                    </button>
                                    <button className="btn-add-outline" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

Wall.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    headWidth: PropTypes.object.isRequired
};

export default Wall;