import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import EventListener from 'react-event-listener';
import PropTypes from 'prop-types';
import Showcase4 from '../Showcase/Showcase4';
import '../../css/videocontrols.css';

import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import EpisodesIcon from '@material-ui/icons/ViewCarousel';
import SettingsIcon from '@material-ui/icons/Settings';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import Forward10Icon from '@material-ui/icons/Forward10';
// import Replay10Icon from '@material-ui/icons/Replay10';


class VideoControls extends Component {
    constructor(props) {
        super(props);

        this.seekbar = React.createRef();

        this.state = {
            seekMouseDown: false,
            seekImageMouseDown: false,
            seekPos: 0,
            seekImgPos: 0,
            // spriteWidth: 250, // should correspond with videocontrols.css
            // spriteHeight: 140, // should correspond with videocontrols.css
            spriteWidth: 160,
            spriteHeight: 90,
            currSeekImg: -1,
            // imgPerRow: 4,
            imgPerRow: 5,
            spriteImgPos: {},
            controlsVisible: true,
            controlsVisibleBlock: false,
            settingsVisible: false,
            settingsQualityVisible: false,
            settingsLanguageVisible: false,
            controlsAlwaysVisible: false,
            episdeListY: -489
        }

        this.holdThumb = this.holdThumb.bind(this);
        this.moveThumb = this.moveThumb.bind(this);
        this.releaseThumb = this.releaseThumb.bind(this);
        this.showSeekImage = this.showSeekImage.bind(this);
        this.hideSeekImage = this.hideSeekImage.bind(this);
        this.clickSeek = this.clickSeek.bind(this);
        this.hideControls = this.hideControls.bind(this);
        this.showSettings = this.showSettings.bind(this);
        this.showSettingsQuality = this.showSettingsQuality.bind(this);
        this.showSettingsLanguage = this.showSettingsLanguage.bind(this);
        this.showEpisodes = this.showEpisodes.bind(this);
        this.hideEpisodes = this.hideEpisodes.bind(this);
    }

    holdThumb() {
        this.setState({ seekMouseDown: true });
        this.props.pauseHandler();
        this.showSeekImage();
    }

    moveThumb(e) {
        this.hideControls();

        if (this.state.seekMouseDown || this.state.seekImageMouseDown) {
            this.setState({
                seekPos: this.getSeekPos(e.clientX),
                seekImgPos: this.getSeekImgPos(e.clientX),
                currSeekImg: this.getSeekImgNr(e.clientX)
            });
        }
    }

    releaseThumb() {
        if (this.state.seekMouseDown) {
            this.setState({ seekMouseDown: false });

            this.props.seekHandler(this.getSec(this.props.duration, this.state.seekPos));
            this.props.togglePlayHandler();
        }
    }

    showSeekImage() {
        this.setState({ seekImageMouseDown: true });
    }

    hideSeekImage() {
        if (!this.state.seekMouseDown) {
            this.setState({ seekImageMouseDown: false });
        }
    }

    clickSeek() {
        this.holdThumb();
    }

    // helper functions
    formatTime(secs_input) {
        if (secs_input / 60 > 60) {
            let hours = Math.floor(secs_input / 3600);
            let mins = Math.floor((secs_input - (hours * 3600)) / 60);
            let secs = Math.floor(secs_input - (mins * 60) - (hours * 3600));

            return hours.toString().padStart(2, '0') + ":" + mins.toString().padStart(2, '0') + ":" + secs.toString().padStart(2, '0');
        }
        else {
            let mins = Math.floor(secs_input / 60);
            let secs = Math.floor(secs_input - (mins * 60));

            return mins.toString().padStart(2, '0') + ":" + secs.toString().padStart(2, '0');
        }
    }

    getSeekPos(clientX) {
        let seekbarWidth = this.seekbar.current.getBoundingClientRect().right - this.seekbar.current.getBoundingClientRect().left;
        let seekPos = ((clientX - this.seekbar.current.getBoundingClientRect().left) / seekbarWidth) * 100;

        if (seekPos > 0) {
            if (seekPos >= 100) {
                seekPos = 100;
            }
        }
        else {
            seekPos = 0;
        }

        return seekPos;
    }

    getSeekImgPos(clientX) {
        let result = this.getSeekPos(clientX);
        let seekbarWidth = this.seekbar.current.getBoundingClientRect().right - this.seekbar.current.getBoundingClientRect().left;
        let imageHalfWidth = ((this.state.spriteWidth / 2) / seekbarWidth) * 100;
        if (result - imageHalfWidth > 0) {
            if ((seekbarWidth - (result / 100) * seekbarWidth) > 115) {
                result = result - imageHalfWidth;
            }
            else {
                result = 100 - (2 * imageHalfWidth);
            }
        }
        else {
            result = 0;
        }

        return result;
    }

    getSeekImgNr(clientX) {
        let imagePercentage = 100 / this.props.imgPerSprite;
        let currImage = Math.floor(this.getSeekPos(clientX) / imagePercentage);
        if (this.state.currSeekImg !== currImage) {
            if (currImage >= this.props.imgPerSprite) {
                currImage = this.props.imgPerSprite - 1;
            }

            let imageX = currImage % this.state.imgPerRow;
            let imageY = Math.floor(currImage / this.state.imgPerRow);

            this.setState({ spriteImgPos: { backgroundPosition: `-${imageX * this.state.spriteWidth}px -${imageY * this.state.spriteHeight}px` } });
        }
    }

    getPercentage(a, b) {
        return ((a / b) * 100) + '%';
    }

    getSec(a, b) {
        return (a / 100) * b;
    }

    hideControls() {
        if (!this.state.controlsVisibleBlock) {
            this.setState({ controlsVisibleBlock: true, controlsVisible: true }, () => {
                setTimeout(() => {
                    if (!this.state.controlsAlwaysVisible) {
                        this.setState({ controlsVisibleBlock: false, controlsVisible: false });
                    }
                }, 3000)
            });
        }
    }

    showSettings() {
        if (this.state.settingsQualityVisible || this.state.settingsLanguageVisible) {
            this.setState({
                settingsVisible: false,
                settingsQualityVisible: false,
                settingsLanguageVisible: false,
                controlsAlwaysVisible: false,
                controlsVisibleBlock: false
            });
        }
        else if (this.state.settingsVisible) {
            this.setState({ settingsVisible: false, controlsAlwaysVisible: false, controlsVisibleBlock: false });
        }
        else {
            this.setState({ settingsVisible: true, controlsAlwaysVisible: true });
        }
    }

    showSettingsQuality() {
        if (!this.state.settingsQualityVisible) {
            this.setState({ settingsQualityVisible: true, settingsVisible: false });
        }
        else {
            this.setState({ settingsQualityVisible: false, settingsVisible: true });
        }
    }

    showSettingsLanguage() {
        if (!this.state.settingsLanguageVisible) {
            this.setState({ settingsLanguageVisible: true, settingsVisible: false });
        }
        else {
            this.setState({ settingsLanguageVisible: false, settingsVisible: true });
        }
    }

    showEpisodes() {
        this.setState({ episdeListY: 0, controlsAlwaysVisible: true });
    }

    hideEpisodes() {
        this.setState({ episdeListY: -489, controlsAlwaysVisible: false, controlsVisibleBlock: false });
    }

    render() {
        const toggleSeekImage = this.state.seekImageMouseDown ? 'block' : 'none';
        const toggleControls = this.state.controlsVisible ? { opacity: '1' } : { opacity: '0' };
        const animeId = this.props.info.id;

        return (
            <div className="controls-container" style={{...toggleControls}}>
                <EventListener
                    target="document"
                    onMouseMove={this.moveThumb}
                    onMouseUp={this.releaseThumb}
                />
                <div className="video-info-shadow"></div>
                <div className="video-info" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: '42px',
                    paddingTop: '25px'

                }}>
                    <img src={this.props.info.image} alt="x Episode Cover" style={{
                        width: '200px',
                        height: 'auto'
                    }} />
                    <div className="video-info-content" style={{
                        marginLeft: '10px'
                    }}>
                        <p className="subtext" style={{ marginBottom: '7px' }}>You're watching</p>
                        <h2 className="semititle">{this.props.info.animeName}</h2>
                        <p className="text">Season {this.props.info.season}</p>
                        <p className="text">Episode {this.props.info.number}</p>
                        <p className="text">{this.props.info.episodeName}</p>
                    </div>
                    <div className="video-info-content-right" style={{
                        marginLeft: 'auto',
                        paddingRight: '42px'
                    }}>
                        <Link to="/browse">
                            <button className="btn-close-video"><CloseIcon /></button>
                        </Link>
                    </div>
                </div>

                <div className="controlbar-shadow"></div>
                <div className="controlbar">
                    <div className="controlbar-inner">
                        <div className="controlbar-settings">
                            <div className="controlbar-settings-inner">
                                <ul className="controlbar-settings-overview" style={!this.state.settingsVisible ? { display: 'none' } : {}}>
                                    <li onClick={this.showSettingsQuality}><span>Quality</span> <span>Auto</span><i className="fas fa-chevron-right"></i></li>
                                    <li onClick={this.showSettingsLanguage}><span>Subtitle</span> <span>English</span><i className="fas fa-chevron-right"></i></li>
                                </ul>
                                <ul className="controlbar-settings-quality" style={!this.state.settingsQualityVisible ? { display: 'none' } : {}}>
                                    <li onClick={this.showSettingsQuality}><i className="fas fa-chevron-left"></i> <span>Quality</span></li>
                                    <li>Auto <span><small>selected</small></span></li>
                                    <li><span>1080p<sup>HD</sup></span></li>
                                    <li><span>720p<sup>HD</sup></span></li>
                                    <li>360p</li>
                                </ul>
                                <ul className="controlbar-settings-sublang" style={!this.state.settingsLanguageVisible ? { display: 'none' } : {}}>
                                    <li onClick={this.showSettingsLanguage}><i className="fas fa-chevron-left"></i> <span>Subtitle</span></li>
                                    <li>Deutsch</li>
                                    <li>English <span><small>selected</small></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="controlbar-inner-seek"
                            onMouseEnter={this.showSeekImage}
                            onMouseLeave={this.hideSeekImage}
                            onMouseDown={this.clickSeek}>
                            <div className="seekthumb-outer">
                                <div
                                    style={this.state.seekMouseDown ? { left: this.state.seekPos + '%' } : { left: this.getPercentage(this.props.timer, this.props.duration) }}
                                    onMouseDown={this.holdThumb}
                                    className="seekthumb">
                                </div>
                            </div>
                            <div
                                ref={this.seekbar}
                                className="seekbar">
                                <div
                                    style={this.state.seekMouseDown ? { width: this.state.seekPos + '%' } : { width: this.getPercentage(this.props.timer, this.props.duration) }}
                                    className="seekbar-current">
                                </div>
                                <div
                                    style={{ width: this.getPercentage(this.props.bufferEnd, this.props.duration) }}
                                    className="seekbar-buffer">
                                </div>
                                <div style={{
                                    background: `url(${this.props.sprite}) no-repeat 0 0 / auto`,
                                    ...this.state.spriteImgPos,
                                    display: toggleSeekImage,
                                    left: this.state.seekImgPos + '%'
                                }}
                                    className="seekbar-seek-image">
                                    <span className="seekbar-seek-time">{this.formatTime(this.getSec(this.props.duration, this.state.seekPos))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="controlbar-inner-bottom">
                            <button className="controlbar-icon" onClick={this.props.togglePlayHandler} style={this.props.playing ? { display: 'none' } : {}}><PlayArrowIcon /></button>
                            <button className="controlbar-icon" onClick={this.props.togglePlayHandler} style={!this.props.playing ? { display: 'none' } : {}}><PauseIcon /></button>
                            <button className="controlbar-icon" onClick={this.props.toggleMuteHandler} style={this.props.muted ? { display: 'none' } : {}}><VolumeUpIcon /></button>
                            <button className="controlbar-icon" onClick={this.props.toggleMuteHandler} style={!this.props.muted ? { display: 'none' } : {}}><VolumeOffIcon /></button>

                            <span className="controlbar-time"><span className="curr-time">{this.formatTime(this.props.timer)}</span> / <span className="duration">{this.formatTime(this.props.duration)}</span></span>

                            <div className="controlbar-inner-right">
                                <button className="controlbar-icon" onClick={this.showEpisodes}><EpisodesIcon /></button>
                                <button className="controlbar-icon" onClick={this.showSettings}><SettingsIcon /></button>
                                <button className="controlbar-icon" onClick={this.props.toggleFullscreenHandler} style={this.props.fullscreen ? { display: 'none' } : {}}><FullscreenIcon /></button>
                                <button className="controlbar-icon" onClick={this.props.toggleFullscreenHandler} style={this.props.fullscreen ? {} : { display: 'none' }}><FullscreenExitIcon /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="episode-container" style={{
                    position: 'absolute',
                    left: '0',
                    bottom: this.state.episdeListY + 'px',
                    padding: '35px 0',
                    backgroundColor: 'rgba(var(--bg-color-semidark-rgb), 1)',
                    zIndex: '11',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'bottom .5s ease-in-out'
                }}>
                    <Showcase4 data={this.props.episodes} cover={false} currentId={animeId} title={"Select Episode"} headWidth={{ maxWidth: '100%', paddingLeft: '60px', paddingRight: '60px' }} />
                    <button className="controlbar-close-episode" onClick={this.hideEpisodes}><ExpandMoreIcon /></button>
                </div>
            </div>
        )
    }
}

VideoControls.propTypes = {
    togglePlayHandler: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    pauseHandler: PropTypes.func.isRequired,
    toggleMuteHandler: PropTypes.func.isRequired,
    muted: PropTypes.bool.isRequired,
    toggleFullscreenHandler: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    timer: PropTypes.number.isRequired,
    seekHandler: PropTypes.func.isRequired,
    sprite: PropTypes.string.isRequired,
    imgPerSprite: PropTypes.number.isRequired,
    bufferEnd: PropTypes.number.isRequired,
    episodes: PropTypes.array.isRequired,
    info: PropTypes.object.isRequired
};

export default VideoControls