import React, { useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ErrorContext } from 'components/context/ErrorProvider';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import '../../css/error.css';

function NotifyError(props) {
    const err = useContext(ErrorContext);

    return (
        <div className="error-container">
            <div className="error-notification" style={{
                position: 'fixed',
                top: '0',
                right: '0',
                zIndex: '10',
                height: '100%',
            }}>
                <TransitionGroup className="error-list">
                    {err.state.error.map((el, index) =>
                        <CSSTransition
                            key={index}
                            classNames="error-item"
                            timeout={500}
                        >
                            <div className="error-item-container" style={{
                                position: 'relative',
                                backgroundColor: '#d66882',
                                padding: '20px 10px',
                                width: '400px',
                                marginBottom: '20px',
                            }}>
                                <div className="error-body" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    color: '#fff'
                                }}>
                                    <div style={{ margin: '0 10px 0 10px' }}><ErrorIcon /></div>
                                    <p>{el.message}</p>
                                    <button className="btn-clear" style={{ margin: '0 10px 0 10px' }} onClick={() => err.removeError(index)}><CloseIcon /></button>
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        </div>
    );
}

export default NotifyError;